<template>
  <tbody class=''>
    <ipo-portfolio-row
      :ipo='ipo'
      :key='ipo.id'
      :show-group-details='showDetails'
      :toggle-details.sync='showDetails'
      @select-row='selectRow' />
    <ipo-portfolio-details
      v-for='detail in detailRows'
      :key='`${ipo.name}-${detail.account_type}`'
      :ipo='ipo'
      :detail='detail'
      :allocated-price='ipo.details["확정공모가"]'
      class='text-xs details-row' />
  </tbody>
</template>

<script>
import IpoPortfolioRow     from '@/components/mobile/IpoPortfolioRow.vue'
import IpoPortfolioDetails from '@/components/mobile/IpoPortfolioDetails.vue'

export default {
  name: 'IpoPortfolioGroup',
  components: {
    IpoPortfolioRow,
    IpoPortfolioDetails,
  },
  props: [
    'ipo',
    'showAll',
  ],
  data () {
    return {
      showDetails: false,
    }
  },
  watch: {
    'showAll': {
      handler: function (newVal) {
        this.showDetails = newVal
      },
      immediate: true
    },
  },
  computed: {
    detailRows () {
      return (this.showDetails) ? this.ipo.portfolios : []
    }
  },
  methods: {
    selectRow () {
      this.$emit('select-row', this.ipo.id)
    },
  },
}
</script>

<style lang='scss'>
  .details-row:last-child td {
    border-bottom-color: #DDDDDD;
  }
</style>
